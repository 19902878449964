import React from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { message, Upload } from "antd"

import { storeResultAttachment } from '../services'
import { handleError } from '../../../helpers'

const { Dragger } = Upload

export default function ResultAttachmentForm({ serviceResultId, reload }) {

    const props = {
        multiple: false,
        customRequest: async ({ file, onError, onSuccess: handleSuccess }) => {
            const fileExt = file.name.split('.').pop().toLowerCase()

            const values = new FormData()
            values.append('file', file)
            values.append('file_path', `app/service_results/${serviceResultId}/${Date.now()}.${fileExt}`)
            values.append('service_result_id', serviceResultId)

            try {
                const response = await storeResultAttachment(values)
                handleSuccess(response.data, file)
                message.success(response.data.message)
                reload()
            } catch (error) {
                handleError(error)
                onError(error)
                console.error('Error al subir el archivo: ', error.message)
            }
        }
    };

    return (
        <div style={{ maxWidth: '400px', margin: 'auto' }}>
            <Dragger {...props} className="px-10">
                <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{ color: '#e78035', fontSize: '41px' }} />
                </p>
                <p className="ant-upload-text">
                    Haga clic o arrastre el adjunto aquí
                </p>
                <p className="ant-upload-hint">
                    Solo se permite adjuntar un archivo menor a 5MB.
                </p>
            </Dragger>
        </div>
    )
}
